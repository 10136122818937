// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);

@include font-face('UnifrakturMaguntia',
    'unifrakturmaguntia-book-webfont.woff',
    'unifrakturmaguntia-book-webfont.ttf',
    'unifrakturmaguntia-book-webfont.svg',
    'unifrakturmaguntia-book-webfont.eot'
);

@include font-face('Bitter',
    'bitter-regular-webfont.woff',
    'bitter-regular-webfont.ttf',
    'bitter-regular-webfont.svg',
    'bitter-regular-webfont.eot'
);

@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 400;
  src: local('Bitter-Regular'), url(fonts/Bitter-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}


@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 700;
  src: local('Bitter-Bold'),  url(fonts/Bitter-Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}


@font-face {
  font-family: 'Bitter';
  font-style: italic;
  font-weight: 400;
  src: local('Bitter-Italic'),  url(fonts/Bitter-Italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'UnifrakturMaguntia';
  font-style: normal;
  font-weight: 400;
  src: local('UnifrakturMaguntia'), url(fonts/UnifrakturMaguntia.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(fonts/OpenSans-Bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}