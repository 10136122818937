/*=NAVIGATION MAIN */
.navi-main {
	@include breakpoint(large){
		display:flex;
		justify-content:space-between;
	}
	li {
		position:relative;
		&.hasSub{
			>a{
				position:relative;
				&:after{

				}
			}
			


			&:hover,&:focus{
				.sub{
					opacity:1;
					visibility:visible;
					overflow:visible;
					
					@include breakpoint(large){
						box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
						top:rem(49px); 
					}
				}
				>a{
				
					&:after{
						width:100%;
					}

				}
			}
		}
		&.active {
			>a{
					&:after{
						opacity:1;
					}

				}
		}

		>a{
			font-size:rem(16px);
			text-transform: uppercase;
			color:$base-1;
			transition:0.3s linear color;
			text-decoration:none;
			position:relative;
		    font-weight: bold;
		    padding-left:rem(10px);
		    padding-top:rem(10px);
		    padding-bottom:rem(10px);
		    border-bottom:rem(1px) dotted $base-1;
		    display: block;
		    transition: 0.3s linear background,0.3s linear color;
		    &:hover,&:focus,&.active{
					background:$base-1;
					color:$base-2;
				}
			 
			@include breakpoint(large){
				color:$base-2;
				padding-left:0;
				border-bottom:0;
				padding-top:0;
		    	padding-bottom:0;
				&:after{
					position:absolute;
					left:0;
					top:50%;
					transform:translateY(-50%);
					content:"";
					width:100%;
					height:rem(60px);
					transition:0.5s linear opacity;
					opacity:0;
					background-position:center center;
					background-size:contain;
					background-repeat:no-repeat;
				
				}
				&:hover,&:focus,&.active{
					background:transparent;
					&:after{
						opacity:1;
					}
				}
				&.about-us:after,&.ueber-uns:after{
					background-image:url("../images/hover-letters/u.png");
				}
				&.hotel:after{
					background-image:url("../images/hover-letters/h.png");
				}
				&.restaurant:after{
					background-image:url("../images/hover-letters/r.png");
				}
				&.events:after,&.veranstaltungen:after{
					background-image:url("../images/hover-letters/v.png");
				}
				&.surroundings:after,&.umgebung:after{
					background-image:url("../images/hover-letters/u-normal.png");
				}
				&.jobs:after,&.rural-environment:after{
					background-image:url("../images/hover-letters/j.png");
				}
				&.contact:after,&.kontakt:after{
					background-image:url("../images/hover-letters/k.png");
				} 
				&.directions:after,&.anfahrt:after{
					background-image:url("../images/hover-letters/a.png");
				}
				&.contact:after,&.partner:after{
					background-image:url("../images/hover-letters/p.png");
				} 
				&.contact:after,&.faq:after{
					background-image:url("../images/hover-letters/f.png");
				} 
			}
		}
	}

	.sub {
		transition:0.3s linear opacity,0.3s linear visibility,0.3s linear top;
		text-align:left;
		z-index: 3;
		@include breakpoint(large){
			opacity:0;
			visibility:hidden;
			position:absolute;
			top:rem(100px);
			min-width:rem(270px);
			left:rem(-10px);
	    	background:rgba(34, 111, 34, 0.6);
	    	overflow:hidden;
	    	&:before{
	    		position:absolute;
	    		top:rem(-10px);
	    		left:0;
	    		height:rem(10px);
	    		width:100%;
	    		content:"";
	    	}
	    
		}
		li {
			display:block;
		}

		a {
			color:$base-1;
			position: relative;
			padding-left:rem(20px);
			display:block;
			overflow:hidden;
			@include breakpoint(large){
				color:$base-2;
				padding-top:rem(10px);
				padding-bottom:rem(10px);
				padding-left:rem(10px);
				&:hover,&:focus,&.active{
					background:$base-1;
				}
			}
			
			
		}
	}
	.privacy-policy,.imprint,.welcome,.startseite,.impressum,.datenschutzerklaerung{
		@include breakpoint(large){
			display:none;
		}
	}
}

// Off-canvas navigation
.page-navi {
	background: $light;
	min-height: 100%;
	transform: translateX(-100%);
	left: 0;
	position: absolute;
	@extend %animated-transform;
	top: 0;
	width: 80%;
	z-index: 100;
	box-shadow: inset rem(-9px) 0px rem(5px) rem(-2px) rgba(0,0,0,0.05);

	@include breakpoint(large) {
		background: transparent;
		transform: translateX(0);
		transition: none;
		min-height: inherit;
		position: relative;
		width: 100%;
		box-shadow:none;
	}
}

.toggle-navi, .close-navi {
	color: $dark;
	font-size: rem(32px);
	text-decoration: none;

	&:before {
		@extend .fa-default;
		@extend .fa-bars;
		line-height: 2.6em;
	    transition: content 300ms, 0.3s linear color,0.3s linear all;
	    display: block;
	    height: 3.75rem;
	    overflow: hidden;
	}

	&:hover {
		&:before {
			color: lighten($base-1,10%);;
		}
	}

	@include breakpoint(large) {
		display: none;
	}
}

.close-navi {
	display: none;
}

.navi-buttons {
	position:fixed;
	width:100%;
	left: 0;
    top: 0;
    background:$base-2;
	z-index:101;
	border-bottom: rem(1px) solid #cacaca;
	@include breakpoint(large){
		display:none;
	}
}

.toggle-navi{
    text-decoration: none;
    float: left;
    text-align: center;
    padding: 0 .625rem;
    display: block;
    height: 100%;
    float: left;
    width:14%;
    border-right:rem(1px) solid #a4a8bc;
}

#navi-toggled:target {
	.page-navi {
		transform: none;
	}

	.page-wrap {
		transform: translateX(80%);
		position: fixed;
		width: 100%;
	}

	.close-navi {
		display: block;
		float:left;
	    padding-left:rem(18px);
	    padding-right:rem(18px);
		&:before {
			@extend .fa-close;
		}
	}

	.toggle-navi {
		display: none;
	}

	@include breakpoint(large) {
		.close-navi {
			display: none;
		}

		.page-wrap {
			transform: none;
			position: relative;
		}
	}
	.navi-buttons {
		position:absolute;
	}
}
///////////////
.mobile-navigation{
	@include breakpoint(large){
		display:none;
	}
}
///////////
.nav-footer{
	background:#3f6337;
	padding-top:rem(43px);
	padding-bottom:rem(43px);
}
.navi-add{
	display:none;
	@include breakpoint(large){
		display:flex;
		justify-content:space-between;
	}
	li{
		a{
			color:$base-2;
			text-decoration: none;
			font-weight:bold;
			&:hover,&:focus,&.active{
				text-decoration:underline;
			}
		}

	}
}
/////
.navi-back{
	background:rgba(34,111,34,0.97) !important;
}


.faq {
	padding: 0 1rem;
}