// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 100%;
		clear:left;
		@include breakpoint(tiny){
			width: 40%;
		}
	}

	dd {
		width: 100%;
		@include breakpoint(tiny){
			width: 60%;
		}
	}
}
 
// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn {
	background: $medium;
	border: none;
	color: $dark;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}
////////////////////
//
.block-holder{
	position:fixed;
	width:100%;
	z-index:99;
	display:none;
	@include breakpoint(large){
		display: block;
	}
	.above-nav{
		background:#feefd8;
		padding-top:rem(40px);
		padding-bottom:rem(32px);
		img{
			width:auto;
			vertical-align: text-top;
		}
		i{
			color:#444444;
			margin-right:rem(12px);
			font-size:rem(18px);
			display: none;
			@include breakpoint(giant){
				display:inline-block;
			}
		}
		.fa-phone{
			margin-right:rem(7px);
		}
		.fa-paper-plane{
			margin-right:rem(10px);
		}
		a{
			text-decoration:none;
			color:#444444;
			font-size:rem(16px);
			font-weight: bold;
			&:hover,&:focus{
				text-decoration:underline;
			}
		}
		.logo-above-nav{
			display:inline-block;
		}
		.info{
			float:right;
			display:inline-block;
			padding-top:rem(10px);
			span{
				font-size:rem(16px);
				font-weight: bold;
			}
			span:nth-child(1){
				margin-right:rem(35px);
			}
			span:nth-child(2){
				margin-right:rem(26px);
			}

		}
	}
	///
	.nav-holder{
		background:rgba(34, 111, 34, 0.6);
		padding-top:rem(26px);
		padding-bottom:rem(25px);
		transition: 0.3s linear background;
	}

}
////////////////
.language{
	padding:rem(10px);
	text-align:center;
}
.languages{
	display: inline-block;
	li:nth-child(1) a{
		margin-right:rem(5px);
		&:before{
			background-position:center center;
			background-image:url("../images/german-flag-h.jpg");
			background-size:cover;
			background-repeat: no-repeat;
			transition:0.3s linear background-image;
		}
		&:hover,&:focus{
				 &:after{
				 	background-image:url("../images/german-flag-h.jpg");
					background-position:center center;
					background-size:cover;
					background-repeat: no-repeat;
					opacity:1;
				 }
		}
		&.active{
			&:before{
			 	background-image:url("../images/german-flag.jpg") !important;
		 	}
		}
	}
	li:nth-child(2) a{
		&:before{
			background-position:center center;
			background-image:url("../images/english-flag-h.jpg");
			background-size:cover;
			background-repeat: no-repeat;
		}
		&:hover,&:focus{
				 &:after{
				 	background-image:url("../images/english-flag-h.jpg");
					background-position:center center;
					background-size:cover;
					background-repeat: no-repeat;
					opacity:1;
				 }
		}
		&.active{
			&:before{
			 	background-image:url("../images/english-flag.jpg") !important;
		 	}
		}
	}
	li{
		display: inline-block;		
		vertical-align: super;		
		a{
			position:relative;
			font-size:0 !important;
			height:rem(20px);
			width:rem(30px);
			display: inline-block;
			transition:0.3s linear all;
			
			&:before{
				content:'';
				width:100%;
				height:100%;
				top:0;
				left:0;
				position: absolute;
			}
			&:after{
				content:'';
				height:100%;
				width: 100%;
				top:0;
				left:0;
				opacity:0;
				position: absolute;
				transition:0.2s linear opacity;
			}

			
		}
		.iexplore &{
			vertical-align: text-bottom;
		}
		
	}
}
// PAGE WRAP
//////////////////////////////
.iexplore .page-wrap {
	overflow:hidden;
}
.page-wrap {
	transform: none;
	transition:0.3s linear all;
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	padding: rem(100px) 0 rem(40px) 0;
	width: 100%;
	background-image:url("../images/header-back.jpg");
	background-position:center center;
	background-size:cover;
	background-repeat:no-repeat;
	text-align:center;
	@include breakpoint(large){
		padding: rem(314px) 0 rem(115px) 0;
		
	}

	.branding {
		display: inline-block;
		padding:rem(18px);
		max-width: 100%;
		img{
			width:auto;
		}

		@include breakpoint(large) {
			float: left;
		}
	}
	.monitor-large{
		@include breakpoint(large){
				display: none;
			}
	}
	///
	.teasers{
		.col{
			margin-bottom:rem(40px);
			@include breakpoint(large){
				margin-bottom:0;
			}
		}
		a{
			color:$base-2;
			&:hover,&:focus{
				text-decoration:none;
			}
		}
		.link{
			position:absolute;
			left:50%;
			bottom:rem(-28px);
			transform:translateX(-50%);
			width:rem(60px);
			height:rem(60px);
			border-radius:50%;
			background:$base-1;
			z-index: 3;
			text-decoration:none;
			&:before{
				position:absolute;
				content:"\f101";
				font-family: $icon-font;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				color:$base-2;
				font-size:rem(30px);
				transition: 0.3s linear transform;

			}
			&:hover,&:focus{
				&:before{
					transform:translate(-50%,-50%) scale(1.3);
				}
			}
		}
		.text-holder{
			border:rem(3px) solid $base-1;
			text-align:center;
			padding:rem(35px) rem(15px) rem(30px) rem(15px);
			background:rgba(76, 137, 75, 0.3);
			position:relative;
			overflow: hidden;
			z-index: 1;
			
			@include breakpoint(small){
				padding:rem(45px) rem(45px) rem(30px) rem(45px);
			}
			@include breakpoint(large){
				padding:rem(75px) rem(45px) 0 rem(45px);
				min-height:rem(468px);
			}
			&:before{
				position:absolute;
				
				font-family: 'UnifrakturMaguntia', cursive; 
				font-size: rem(413px);
				color:$light;
				left:rem(-40px);
				height: rem(133px);
				bottom:0;
				opacity:0.1;
				z-index: -1;
			}
			.heading{
				color:$base-2;
				margin-top:0;
				transition:0.3s linear border,0.3s linear margin-top;
				
				@include breakpoint(large){
					border-bottom:0;
					margin-top:rem(80px);
				}
				.head{
					font-size:rem(26px);
					margin-bottom:rem(40px);
					padding-bottom:rem(21px);
					display: block;
					border-bottom:rem(5px) solid $base-2;
					span{
						font-family: 'UnifrakturMaguntia', cursive; 
						font-size:rem(40px);
					}
				}
			}
			p{
				color:$base-2;
			}
			.text{
				position:relative;
				visibility: visible;
				opacity: 1;
				left:50%;
			    width: 90%;
				transform:translateX(-50%);
				transition:0.3s linear visibility,0.3s linear opacity,0.3s linear bottom;
				@include breakpoint(medium){
					width: 75%;
				}
				@include breakpoint(large){
					bottom:0;
					visibility:hidden;
					opacity:0;
					position:absolute;
					
				}
			}
		//////
			&:hover,&:focus{
				@include breakpoint(large){
					.text{
						bottom:rem(110px);
						visibility: visible;
						opacity: 1;
					}
					.heading{
						margin-top:0;
						.head{
							border-bottom:rem(5px) solid $base-2;
							
						}
						
					}
				}
			}
		}
		.t1:before{
			content: "H";
		}
		.t2:before{
			content: "R";
		}
		.t3:before{
			content: "V";
		}
		
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
	h1{
		position:relative;
		margin-bottom:rem(86px);
		&:before{
			position:absolute;
			bottom:rem(-24px);
			left:50%;
			transform:translateX(-50%);
			height:rem(5px);
			width:rem(273px);
			background:#686765;
			content: "";
		}
	}
	.under-heading{
		display: block;
		color:#444444;
		font-size:rem(18px);
		font-family: $display-font;
		font-weight:500;
		line-height: 1.5em;
		@include breakpoint(large){
			font-size:rem(24px);
		}
	}
	.sec1{
		padding-top:rem(115px);
		padding-bottom:rem(171px);
		@include breakpoint(medium){
			background-image:url("../images/sec1-back.png");
			background-repeat:no-repeat;
			background-position:center;
			background-size:cover;
		}
		
		p{
			line-height:1.7em;
			text-align: center;
		}
		.short-p{
			max-width:rem(621px);
			margin:rem(30px) auto 0 auto;
			span{
				font-weight:bold;
			}
		}
	}
	///
	.sec2{
		padding-bottom:rem(66px);
		.picture-block{
			margin-top:rem(-60px) !important;
		}
		.heading{
			color:$base-1;
			font-size:rem(22px);
			font-weight:bold;
			text-align: center;
			padding-top:rem(50px);
			padding-bottom:rem(70px);
			span{
				font-family: 'UnifrakturMaguntia', cursive; 
				font-size:rem(40px);
			}
			@include breakpoint(medium){
				padding-top:rem(105px);
				font-size:rem(28px);
				span{
					font-size:rem(48px);
				}
			}
		}
		p{
			line-height:1.8em;
			margin-bottom:rem(28px);
		}
	}
	////
	.slider-content-holder{
		background-image:url("../images/slider-back.jpg");
		background-repeat:no-repeat;
		background-position:center;
		background-size:cover;
		min-height:rem(210px);
		padding-top:rem(47px);
		padding-bottom:rem(22px);
		.slider-content{
			width:100%;
			.slick-list{
				padding:0 rem(10px);
				outline: none;
				@include breakpoint(small){
					padding:0;
				}
			}
			.slick-slide{
				outline: none;
			}
		}
		.heading{
			font-size:rem(26px);
			font-weight:bold;
			color:$base-2;
			display: inline-block;
			padding:2.625rem 0;
			span{
				font-family: 'UnifrakturMaguntia', cursive; 
				font-size:rem(40px);
			}
		}
		p{
			color:$base-2;
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	.map-index{
		position:relative;
		border-top:rem(2px) solid $base-1;
		border-bottom:rem(3px) solid $base-1;
		height:rem(388px);
		iframe{
			height:rem(382px);
		}
		.overlay-map{
			position:absolute;
			width:100%;
			height:100%;
			top:0;
			left:0;
			background:rgba(76, 137, 75, 0.7);
			text-align:center;
			text-decoration:none;
			cursor:pointer;
			transition:0.3s linear all;
			

		}
		.info-footer{
			position:absolute;
			top:rem(90px);
			left:50%;
			transform:translateX(-50%);
			display: inline-block;
			text-align:center;
			transition: 0.3s linear all;
			max-width:95%;
			width:100%;
			@include breakpoint(small){
				width:auto;
			}
			a{
				text-decoration:none;
				color:$base-2;
				&:hover,&:focus{
					text-decoration:underline;
				}
			}
			span{
				display: block;
				color:$base-2;
				font-size:rem(18px);
				font-weight:bold;
				font-family: 'Open Sans', sans-serif;
				line-height: 1.6em;
			}
			.heading{
				font-size:rem(26px);
				font-family: $main-font;
			}
			.tel{
				padding-top:rem(25px);
			}
		}
		
	}
	.socials{
		img{
			width:auto;
		}
		a{
			display: inline-block;
			margin-right:rem(5px);
			transition: 0.3s linear opacity;
			&:last-child{
				margin-right:0;
			}
			&:hover,&:focus{
				opacity:0.7;
			}
		}
	}
}
///////////
////aditional classes
.picture-block{
	margin-top:rem(40px);
	@include breakpoint(medium){
		margin-top:rem(90px);
	}
}
.mobi-tel,.mobi-mail{
	position:relative;
	color:#444444;
	text-decoration:none !important;
}
.mobi-mail{
	width:86%;
	float:right;
	border-right:rem(1px) solid #a4a8bc;
	text-align: center;
    padding-top:rem(10px);
    padding-bottom:rem(10px);
    font-size:rem(14px);
    transition: 0.3s linear all;
    a{
    	color:#444444;
    	text-decoration:none !important;
    	display: block;
    	font-weight:bold;
    	&:hover,&:focus{
			color:$base-1;
		}
    }
   
	@include breakpoint(small){
		font-size:rem(18px);
		height:rem(86px);
		width:43%;
		padding-top:rem(27px);
		padding-bottom:0;
	}
}
.mobi-tel{
	width:86%;
	float:right;
	border-right:rem(1px) solid #a4a8bc;
    text-align: center;
    padding-top:0;
    padding-bottom:rem(10px);
    font-size:rem(14px);
    font-weight:bold;
    transition: 0.3s linear all;
    &:hover,&:focus{
		color:$base-1;
	}
	@include breakpoint(small){
		font-size:rem(18px);
		width:43%;
		float:left;
		padding-top:rem(27px);
		height:rem(86px);
	}
}
///
.video-uber-uns iframe{
	height:100%;
	width:100%
}
.no-overlay{
	left:-110% !important;
}
.pic1{
	&:before{
		content:"";
		top:50%;
		left:50%;
		transform:translate(-50%,-50%) !important;
		width:0;
		height:0;
		background:rgba(76, 137, 75, 0.3);
		

	}
	 &:hover,&:focus{
	 	&:before{
	 		width:90%;
	 		height:90%;
	 		border:rem(3px) solid $base-1;
	 	}
	}
}
.pic2{
	&:before{
		content:"";
		top:50%;
		left:50%;
		transform:translate(-50%,-50%) !important;
		width:0;
		height:0;
		background:rgba(76, 137, 75, 0.3);
		

	}
	 &:hover,&:focus{
	 	&:before{
	 		width:90%;
	 		height:90%;
	 		border:rem(3px) solid $base-1;
	 	}
	}
}
//////////
///
body:not(.index,.en-index){
	main{
		padding:rem(45px) 0 rem(45px) 0;
		@include breakpoint(medium){
			padding:rem(115px) 0 rem(66px) 0;
			background-image:url("../images/sec1-back.png");
			background-repeat:no-repeat;
			background-position:left top;
			background-size:100%;
		}
	}
	.teasers{
		display:none;
	}
	header{
		padding-top:rem(86px);
		padding-bottom:0;
		@include breakpoint(large){
			padding: rem(314px) 0 rem(115px) 0;
		}
	}
}
////////
body.anfahrt,body.en-directions{
	.map-index{
		display: none;
	}
}
///nojs
.nojs{
	.escape.email{
  		display: inline-block;
 		 span{
   			display: inline-block;
  			 margin:0;
  			 span{
   				 display: inline-block;
			}
 		 }
 	}

 	.slider-content{
	.no-js-none{
		display:none;
	}
}

}